import React, { useState, useEffect, useLayoutEffect } from "react"
import { useNavigate } from 'react-router-dom';
import { useProfile } from "../../../../services/utils/profileContext";
import { useAccountSettings } from "../../../../services/Context/AccSettingContext";
import { dateFormat, getAge, getSearchParams, removeSearchParam, updateSearchParams } from "../../../../services/utils/validation";
import ScheduleHeader from "../../consultation/summary/ScheduleHeader";

export default function SelectFamilyMember() {

    const [selectedMember, setSelectedMember] = useState(null)
    const { contextFamilyList } = useProfile();
    const { accountDate } = useAccountSettings();
    const navigate = useNavigate();


    const selectFamilyMember = (id, dob) => {
        if (getAge(dob) < 18) {
            setSelectedMember(id);
            updateSearchParams("fmid", id);
        }
    }

    const mySelf = () => {
        setSelectedMember(null);
        removeSearchParam("fmid");
    }

    const handleNext = () => {
        const locationSearch = window.location.search;
        return navigate(`/booking${locationSearch}`);
    }

    useLayoutEffect(() => {
        let _orderDetails = localStorage.getItem("orderDetail")

        if (_orderDetails) {
            _orderDetails = JSON.parse(_orderDetails);
            let _stateId = _orderDetails?.profile?.State
            if (_stateId) {
                updateSearchParams("rsid", _stateId)
            }
        }
    }, [])

    useEffect(() => {

        document.title = "Who is this consultation for";

        const _familyMemberId = getSearchParams("fmid");

        if (_familyMemberId) {
            setSelectedMember(_familyMemberId);
        }
    }, [])
    //console.log(getAge("1998-05-01"));

    return (
        <>
            {Array.isArray(contextFamilyList) && contextFamilyList?.length > 0 && (
                <>
                    <ScheduleHeader title={"Who is this consultation for ?"} />
                    <div className="mb-3">
                        <small className="text-secondary">Select a family member or yourself to proceed with scheduling an appointment or consultation</small>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-3" onClick={mySelf}>
                            <div className={`card pointer-class ${selectedMember ? "border border-1" : "theme-border specialty-svg-icons-selected"}`}>
                                <div className="card-body text-center">
                                    <h6 className="m-0">For myself</h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mb-3">
                        <span className="text-secondary">*To set up a consultation for an adult family member, please invite them from your profile. They will need to log into the portal and complete the request</span>
                    </div>
                    <div className="row g-2 g-md-3">
                        {contextFamilyList?.filter((item) => item?.statusId == "1" && item?.lockoutEnabled !== true)?.map((list, index) => (
                            <div className="col-md-6 col-lg-3" key={index}>
                                <div className={`card ${getAge(list?.dob) >= 18 ? "bg-adult-family-member" : "pointer-class"} ${selectedMember == list?.id ? "theme-border specialty-svg-icons-selected" : ""} `} onClick={() => selectFamilyMember(list?.id, list?.dob)}>
                                    <div className="card-body text-center">
                                        <h6 className="m-0">{list?.firstName} {list?.lastName}</h6>
                                        <small className={`${selectedMember == list?.id ? "" : "text-secondary"} `}>{list?.gender} | {dateFormat(list?.dob, accountDate?.value)}</small>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="my-5">
                        <div className="text-end">
                            <button onClick={handleNext} className="btn btn-lg btn-primary">Proceed</button>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}