import React, { useState, useEffect, useContext } from "react";
import { serverGet } from "../../../middleware/http";
import { apiConst } from "../../../services/constant/constant";
import { useProfile } from "../../../services/utils/profileContext";
import { dateTimeFormat, getEncounterColor, timeFormat } from "../../../services/utils/validation";
import ProgressContext from "../../../services/utils/progress";
import { useAccountSettings } from "../../../services/Context/AccSettingContext";

export default function Consultation() {
    const [consultationList, setConsultationList] = useState(null);
    const { patientInfo } = useProfile();
    const { progParams } = useContext(ProgressContext);
    const { accountDate } = useAccountSettings();

    const getlist = (id) => {
        try {
            progParams(true);
            serverGet(`${apiConst.encounterList}${id}`).then((res) => {
                if (res?.status == 1 && res?.data) {
                    setConsultationList(res?.data);
                }
                progParams(false);

            }).catch((e) => {
                console.log(e)
            });


        } catch (e) {
            console.error(e)
        }
    }


    useEffect(() => {
        document.title = "Consultation";
        if (patientInfo?.id) {
            getlist(patientInfo?.id);
        }

    }, [patientInfo])

    return (
        <>
            {(Array.isArray(consultationList) && consultationList?.length > 0) && (
                <>
                    <h4 className="mb-4">Consultation</h4>
                    <div className="card border-0">
                        <div className="card-body p-0 px-2 py-md-3 px-md-4 mb-5">
                            {consultationList?.map((item) => (
                                <div className="border p-3 rounded-3 mt-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h6 className="fw-bold m-0">{item?.patientName}</h6>
                                        <button type="button" className={`py-0 btn ${getEncounterColor(item?.statusId)} btn-sm`}>{item?.encounterStatus}</button>
                                    </div>
                                    <div className="mb-2">
                                        <small><span>#{item?.encounterNo}</span> | <span>{item?.reason}</span></small>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-5 col-xl-4 d-flex align-items-center mb-2 m-lg-0 ">
                                            <img className="flex-shrink-0 rounded-circle border me-2" width="50" height="50" src={item?.providerPhoto ? "doc/getimage/true?url=" + encodeURIComponent(item?.providerPhoto) : require('../../../asset/images/blankuser.png')} alt="User Profile" />
                                            <div>
                                                <h6 className="fw-bold m-0">{item?.providerName}</h6>
                                                <small>{item?.specialityName}</small>
                                            </div>
                                        </div>
                                        <div className="col-lg-7 col-xl-8">
                                            <div className="row">
                                                <div className="col-5">
                                                    <div className="text-secondary">
                                                        <small>Appointment on</small>
                                                    </div>
                                                    <div>
                                                        {dateTimeFormat(item?.encounterOn, accountDate?.value)} {timeFormat(item?.encounterOn)}
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="text-secondary">
                                                        <small>Mode</small>
                                                    </div>
                                                    <div>
                                                        {item?.encounterMode}
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="text-secondary">
                                                        <small>Type</small>
                                                    </div>
                                                    <div>
                                                        {item?.consultationType}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </>
    )
}